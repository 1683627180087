<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Boards: "Tabuleiros",
      "BACK": "VOLTAR",
      "To start your board you need to donate to your sponsor.": "Para iniciar o seu tabuleiro você precisa realizar a doação para o seu patrocinador.",
      "Sponsor Information": "Informações do Patrocinador",
      "Donate": "Doar",
      "VIEW": "VER",
      "COMPLETED": "COMPLETO",
      "ENTER": "ENTRAR",
      "LOCKED": "BLOQUEADO",
      "Click here to make the payment for the Station 10 license in the amount of $8.00": "Clique aqui e realize o pagamento da licença da Estação 10 no valor de US$ 8,00",
      "Make the payment for the Station 10 license in the amount of $8.00": "Realize o pagamento da licença da Estação 10 no valor de US$ 8,00",
    },
    es: {
      Boards: "Tableros",
      "BACK": "VOLVER",
      "To start your board you need to donate to your sponsor.": "Para comenzar su tablero, debe donar a su patrocinador.",
      "Sponsor Information": "Información de Patrocinador",
      "Donate": "Donar",
      "VIEW": "VER",
      "COMPLETED": "COMPLETO",
      "ENTER": "ENTRAR",
      "LOCKED": "BLOQUEADO",
      "Click here to make the payment for the Station 10 license in the amount of $8.00": "Haz clic aquí para realizar el pago de la licencia de la Estación 10 por un valor de $8.00",
      "Make the payment for the Station 10 license in the amount of $8.00": "Realiza el pago de la licencia de la Estación 10 por un valor de $8.00",
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      types: null,
      sponsor: false,
      license: null,

      info: {
        default: {
          modal: true,
          name: '',
          cellphone: '',
          whatsapp: '',
        }
      }
    };
  },
  methods: {
    getBoard() {
      this.types = null;

      api.get("station10/boards").then((response) => {
        if (response.data.status == "success") {
          this.types = response.data.list;
        } else {
          this.$router.push("/");
        }
      });
    },
    getBoardDefault() {
      api.get("boards/default").then((response) => {
        if (response.data.status == "success") {
          this.info.default.name = response.data.board.name;
          this.info.default.cellphone = response.data.board.cellphone;
          this.info.default.whatsapp = response.data.board.whatsapp;
        }
      });
    },
    getLicensePending() {
      this.license = null;

      api.get("station10/boards/license/pending").then((response) => {
        if (response.data.status == "success") {
          this.license = response.data.list;
        }
      });
    },
  },
  mounted() {
    this.getBoard();
    this.getBoardDefault();
    this.getLicensePending();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between align-items-center">
          <h4 class="mb-0 font-size-18">{{ t("Estação 10") }}</h4>
          <div class="ml-2">
            <button class="btn btn-light btn-sm ml-2" v-on:click="getBoard(); getLicensePending();"><i class="lnr lnr-sync "></i></button>
          </div>
        </div>
      </div>
    </div>

    <template v-if="license && license.length > 0">
      <button v-on:click="info.default.modal = true" class="border-0 w-100 text-left bg-soft-danger p-3 mb-4 rounded d-flex align-items-center">
        <i class="lnr lnr-license text-dark" style="font-size: 30px"></i>
        <div class="px-3 flex-fill pt-1">
          <h6 class="m-0 font-size-15">
            {{ t("Click here to make the payment for the Station 10 license in the amount of $8.00") }}
          </h6>
        </div>
      </button>
    </template>

    <b-modal v-if="license && license.length > 0" v-model="info.default.modal" :title="t('Licença')" centered>
      <div class="text-center">
        <div class="row justify-content-center mb-3">
          <h5 class="col-md-10">{{ t("Make the payment for the Station 10 license in the amount of $8.00") }}</h5>
        </div>
        <div class="mb-3">
          <img class="rounded-circle header-profile-user" src="@/assets/images/logo-icon.png" alt="" style="width: 100px; height: 100px" />
        </div>
        <div class="font-size-16 notranslate">
          {{ info.default.name }}<br />
          {{ info.default.cellphone }}<br /><br />

          <a class="btn btn-success" target="_blank" :href="'https://api.whatsapp.com/send?phone=' +
            info.default.whatsapp +
            '&text=Olá! Sou o usuário @' + account.user.username + ', preciso dos dados para realizar o pagamento da licença da Estação 10 no valor de US$ 8,00'
            ">
            <i class="bx bxl-whatsapp font-size-24 p-0 align-middle"></i>
            Entrar em contato
          </a>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div v-if="!types" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
    </div>
    <div class="boards" v-else-if="types && types.length > 0">
      <div class="boards-column mb-4" v-for="(row, index) in types" :key="index">
        <template v-if="row.position.status == 'pending'">
          <router-link tag="a" :to="'/station10/' + row.position.id">
            <div class="card type m-0">
              <div class="card-body rounded text-center text-white" :class="row.id">
                <div class="type-pin"><img :src="require('@/assets/images/boards/station10/' + row.id + '-' + row.level + '.png')" /></div>
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1">
                  {{ t('VIEW') }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'active'">
          <router-link tag="a" :to="'/station10/' + row.position.id">
            <div class="card type m-0">
              <div class="card-body rounded text-center text-white" :class="row.id">
                <div class="type-pin"><img :src="require('@/assets/images/boards/station10/' + row.id + '-' + row.level + '.png')" /></div>
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1">
                  {{ t('VIEW') }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'completed'">
          <router-link tag="a" :to="'/station10/' + row.position.id">
            <div class="card type m-0">
              <div class="card-body rounded text-center text-white" :class="row.id">
                <div class="type-pin"><img :src="require('@/assets/images/boards/station10/' + row.id + '-' + row.level + '.png')" /></div>
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1">
                  {{ t("COMPLETED") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'enter'">
          <router-link tag="a" :to="'/station10/' + row.position.id">
            <div class="board card type m-0">
              <div class="card-body rounded text-center text-white" :class="row.id">
                <div class="type-pin"><img :src="require('@/assets/images/boards/station10/' + row.id + '-' + row.level + '.png')" /></div>
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1">
                  {{ t("ENTER") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'preview'">
          <router-link tag="a" :to="'/station10/' + row.position.id">
            <div class="board card type m-0">
              <div class="card-body rounded text-center text-white" :class="row.id">
                <div class="type-pin"><img :src="require('@/assets/images/boards/station10/' + row.id + '-' + row.level + '.png')" /></div>
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1">
                  {{ t("ENTER") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'locked'">
          <div class="card type m-0">
            <div class="card-body rounded text-center text-white" :class="row.id">
              <div class="type-pin"><img :src="require('@/assets/images/boards/station10/' + row.id + '-' + row.level + '.png')" /></div>
              <h4 class="m-0 text-white">{{ row.name }}</h4>
              <div class="type-donation">
                {{ t('Donate') }} {{ row.donation | currency }}
              </div>
              <div class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1">
                {{ t("LOCKED") }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.boards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.boards-column {
  flex-basis: calc(100% / 6);
  box-sizing: border-box;
  margin: 0 10px;
  min-width: 200px;
}

.boards::after {
  margin-left: 0;
}

.boards::before {
  margin-right: 0;
}

.boards::after {
  order: 1;
}

.boards-column:nth-child(n+6) {
  order: 2;
}

@media (max-width: 700px) {
  .boards-column {
    min-width: calc(90vw);
  }
}

.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}

.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}

.start {
  background: #104F50;
  background: linear-gradient(to bottom, #104F50 0%, #2AAAA8 100%);
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #104F50),
      color-stop(100%, #2AAAA8));
  background: -webkit-linear-gradient(top, #104F50 0%, #2AAAA8 100%);
  background: -moz-linear-gradient(top, #104F50 0%, #2AAAA8 100%);
  background: -o-linear-gradient(top, #104F50 0%, #2AAAA8 100%);
  background: -ms-linear-gradient(top, #104F50 0%, #2AAAA8 100%);
}

.connect {
  background: #9E0EA0;
  background: linear-gradient(to bottom, #9E0EA0 0%, #E81CFE 100%);
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #9E0EA0),
      color-stop(100%, #E81CFE));
  background: -webkit-linear-gradient(top, #9E0EA0 0%, #E81CFE 100%);
  background: -moz-linear-gradient(top, #9E0EA0 0%, #E81CFE 100%);
  background: -o-linear-gradient(top, #9E0EA0 0%, #E81CFE 100%);
  background: -ms-linear-gradient(top, #9E0EA0 0%, #E81CFE 100%);
}

.pending .card-body {
  filter: grayscale(1);
  opacity: 0.5;
}

.type-pin {
  margin-bottom: 15px;
}

.type-pin img {
  width: 100px;
}
</style>
